module controllers {
  export module tariff {
    interface ITariffBookNotesUpdateScope extends ng.IScope {}

    interface ITariffBookNotesParams extends ng.ui.IStateParamsService {
      tnnId: number;
    }

    export class tariffBookNotesUpdateCtrl {
      static $inject = [
        "$scope",
        "$rootScope",
        "generalService",
        "$q",
        "bsLoadingOverlayService",
        "entityService",
        "$timeout",
        "$uibModal",
        "tariffBookNotesService",
        "$state",
        "$stateParams",
        "$transitions",
        "statusService",
        "classificationValueService",
        "tariffCountryService",
        '$anchorScroll',
        'CopyNote'
      ];

      tnnId: number;
      breadCrumbDesc: string;
      tariffBookNote: interfaces.tariffbooknotes.ITariffBookNote;
      selectedTab: number = 0;
      gridCountryExceptionApi: uiGrid.IGridApi;
      originalStatus: number = 0;

      viewNotesEnglishUKHTML: boolean = false;
      NotesEnglishUKHTML: string;

      viewNotesFrenchHTML: boolean = false;
      NotesFrenchHTML: string;

      viewNotesSpanishHTML : boolean = false;
      NotesSpanishHTML: string;

      viewNotesPortugueseHTML: boolean = false;
      NotesPortugueseHTML: string;

      constructor(
        private $scope: ITariffBookNotesUpdateScope,
        private $rootScope: interfaces.applicationcore.IRootScope,
        public generalService: interfaces.applicationcore.IGeneralService,
        private $q: ng.IQService,
        private bsLoadingOverlayService,
        private entityService: interfaces.applicationcore.IEntityService,
        private $timeout: ng.ITimeoutService,
        private $uibModal: ng.ui.bootstrap.IModalService,
        private tariffBookNotesService: interfaces.tariffbooknotes.ITariffBookNotesService,
        private $state: ng.ui.IStateService,
        private $stateParams: ITariffBookNotesParams,
        private $transitions: ng.ui.core.ITransition,
        private statusService: interfaces.master.IStatusService,
        private classificationValueService: interfaces.applicationcore.IClassificationValueService,
        private tariffCountryService: interfaces.tariff.ITariffCountryService,
        private $anchorScroll: ng.IAnchorScrollService,
        private CopyNote: number
      ) {
        console.log("CopyNote",this.CopyNote);
        this.tnnId = $stateParams.tnnId;
        this.loadTariffBookNote();
      }

      loadTariffBookNote() {
        this.bsLoadingOverlayService.wrap(
          {
            referenceId: "tariffbooknote.update",
          },
          () => {

// call tariffBookNotesService.getTariffBookNote passing in tnnId and copyNote as URL parameters



            return this.tariffBookNotesService.getTariffBookNote().get(
              {
                tnnId: this.tnnId,
                copyNote: this.CopyNote,
              },
              (result: interfaces.tariffbooknotes.ITariffBookNote) => {
                
                this.tariffBookNote = result;
                this.originalStatus = this.tariffBookNote.Status.Id;

                this.tariffBookNote.StartDate = moment.utc(
                  moment(this.tariffBookNote.StartDate).format(
                    "YYYY-MM-DDTHH:mm:ss"
                  )
                );

                if (this.tariffBookNote.EndDate)
                  this.tariffBookNote.EndDate = moment.utc(
                    moment(this.tariffBookNote.EndDate).format(
                      "YYYY-MM-DDTHH:mm:ss"
                    )
                  );


                  if (this.tariffBookNote.PublishedOn)
                  this.tariffBookNote.PublishedOn = moment.utc(
                    moment(this.tariffBookNote.PublishedOn).format(
                      "YYYY-MM-DDTHH:mm:ss"
                    )
                  );               
                  
                  if (this.tariffBookNote.CreateStampDate)
                  this.tariffBookNote.CreateStampDate = moment.utc(
                    moment(this.tariffBookNote.CreateStampDate).format(
                      "YYYY-MM-DDTHH:mm:ss"
                    )
                  );    

                  if (this.tariffBookNote.UpdateStampDate)
                  this.tariffBookNote.UpdateStampDate = moment.utc(
                    moment(this.tariffBookNote.UpdateStampDate).format(
                      "YYYY-MM-DDTHH:mm:ss"
                    )
                  );                      

                  if (this.tariffBookNote.TariffBookNoteExceptionList){
                    this.tariffBookNote.TariffBookNoteExceptionList.forEach((x)=>{
                      x.StartDate = moment.utc(
                        moment(x.StartDate).format(
                          "YYYY-MM-DDTHH:mm:ss"
                        )
                      );
                    })
                  }

                this.breadCrumbDesc = this.tariffBookNote.Title;
                this.gvwCountryException.data =
                  this.tariffBookNote.TariffBookNoteExceptionList;

                  this.NotesEnglishUKHTML = this.tariffBookNote.NotesEnglishUK;
                  this.NotesFrenchHTML = this.tariffBookNote.NotesFrench;
                  this.NotesSpanishHTML = this.tariffBookNote.NotesSpanish;
                  this.NotesPortugueseHTML = this.tariffBookNote.NotesPortuguese;

                this.$timeout(() => {});
              },
              (errorResponse) => {
                this.generalService.displayMessageHandler(
                  <interfaces.applicationcore.IMessageHandler>errorResponse.data
                );
              }
            ).$promise;
          }
        );
      }

      loadStatuses() {

        if (this.tariffBookNote.Status.Id !== 671){
          return this.classificationValueService
            .GetClassificationValueDropdownByClassification()
            .query(
              {
                classificationId:
                  Enum.ClassificationEnum.TariffAmendmentRestrictionStatus,
              },
              (resultList:interfaces.applicationcore.IDropdownModel[]) => {

              }
            ).$promise;
        }
        else{
          var DraftStatus   = {
            Id: 670,
            Code:"",
            Description:"Draft",
            Display: "Draft",
            Selected: false
          }

          var resultList = [];
          resultList.push(DraftStatus);

          return Promise.resolve(resultList);

        }

      }

      lodeCodeTypes() {
        return this.classificationValueService
          .GetClassificationValueDropdownByClassification()
          .query(
            {
              classificationId: Enum.ClassificationEnum.TariffCodeType,
            },
            (resultList) => {}
          ).$promise;
      }

      loadActiveTariffcountries() {
        return this.tariffCountryService
          .getActiveTariffCountries()
          .query({}, (resultList) => {}).$promise;
      }

      public registerCountryExceptionGridApi(gridApi: uiGrid.IGridApi) {
        this.gridCountryExceptionApi = gridApi;

        this.$timeout(() => {
          this.gvwCountryException.data =
            this.tariffBookNote.TariffBookNoteExceptionList;
        });
      }

      public gvwCountryException: uiGrid.IGridOptions | any = {
        data: [],
        enableSorting: true,
        enableFiltering: true,
        useExternalFiltering: false,
        enableCellEdit: false,
        useExternalSorting: false,
        multiSelect: false,
        enableColumnResizing: true,
        enableRowSelection: false,
        enableFullRowSelection: false,
        enableRowHeaderSelection: false,
        enableHorizontalScrollbar: 2,
        enablePinning: false,
        rowEditWaitInterval: -1,
        paginationPageSizes: [10, 20, 50, 100],
        paginationPageSize: 10,
        enableVerticalScrollbar: 1,
        useExternalPagination: true,
        enableGridMenu: true,
        exporterMenuCsv: false,
        exporterMenuPdf: false,
        exporterExcelFilename: "CountryExceptionist.xlsx",
        gridMenuShowHideColumns: false,
        exporterFieldCallback: (grid, row, col, value) => {
          return this.generalService.handleValue(value);
        },
        exporterSuppressColumns: ["ADD"],
        onRegisterApi: (gridApi) => {
          this.registerCountryExceptionGridApi(gridApi);
        },
        columnDefs: [
          {
            name: "ADD",
            displayName: "",
            enableFiltering: false,
            enableSorting: false,
            enableColumnMenu: false,
            width: 56,
            cellTemplate: `
            <div class="GridButton"> 
                    <button type="button"  ng-click="grid.appScope.tariffBookNotesUpdateCtrl.DeleteCountryException(row.entity)" class="btn btn-warning btn-sm" >
                        Delete
                    </button>
            </div>`,
            headerCellTemplate: `
                <div class="GridButton"> 
                    <button type="button"  class="btn btn-default btn-sm" ng-click="grid.appScope.tariffBookNotesUpdateCtrl.addInlineCountryException()" >
                        <span class="fa fa-plus"></span>
                    </button>
                </div>`,
          },
          {
            name: "Country",
            displayName: "* Country",
            field: "Country",
            width: 250,
            cellTemplate: `<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD.Display}}">{{MODEL_COL_FIELD.Display}}
            </div>`,
            editableCellTemplate: `
                <form name="inputForm">
                    <gts-dropdown  ui-select-wrap name="Country-{{MODEL_COL_FIELD.$$hashKey}}" append-to-body="true" ng-model="MODEL_COL_FIELD" form="inputForm" load-data="grid.appScope.tariffBookNotesUpdateCtrl.loadActiveTariffcountries()" pagination-search="true" refresh-delay="1000"></gts-dropdown>
                </form>`,
            enableCellEditOnFocus: true,
            enableCellEdit: true,
          },
          {
            name: "STARTDATE",
            displayName: "Start Date",
            field: "StartDate",
            enableCellEditOnFocus: true,
            enableCellEdit: true,
            filterHeaderTemplate: `
              <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                  <gts-date format="yyyy/MM/dd" ng-model="colFilter.term" name="StartDate" ></gts-date>
              </div>`,
            cellTemplate: `<div class="ui-grid-cell-contents">{{MODEL_COL_FIELD | momentDateFilter: "YYYY/MM/DD"}}</div>`,
            editableCellTemplate: `
                        <form name="inputForm">
                                <gts-date format="yyyy/MM/dd"  ng-model="MODEL_COL_FIELD" name="{{MODEL_COL_FIELD.$$hashKey}}-startDate" ng-required="true"></gts-date>
                        </form>`,
            enableSorting: false,
            width: 150,
          },
          {
            name: "Active",
            displayName: "Active",
            field: "IsActive",
            width: 100,
            cellTemplate:
              '<input type="checkbox" ng-model="MODEL_COL_FIELD" ng-change="grid.appScope.tariffBookNotesUpdateCtrl.setRowDirtyCountryException(row.entity)">',
          },
        ],
      };

      setRowDirtyCountryException(
        entity: interfaces.tariffbooknotes.TariffBookNoteException
      ) {
        if (this.gridCountryExceptionApi) {
          this.gridCountryExceptionApi.rowEdit.setRowsDirty([entity]);
        }
      }

      DeleteCountryException(
        entity: interfaces.tariffbooknotes.TariffBookNoteException
      ) {
        if (entity.Id > 0) {
          entity.IsActive = false;
          if (this.gridCountryExceptionApi) {
            this.gridCountryExceptionApi.rowEdit.setRowsDirty([entity]);
          }
        } else {
          _.remove(this.gvwCountryException.data, (o) => {
            return o === entity;
          });

          var removeArr: Array<interfaces.tariffbooknotes.TariffBookNoteException> =
            [];
          removeArr.push(entity);
          this.gridCountryExceptionApi.rowEdit.setRowsClean(removeArr);
        }
      }

      addInlineCountryException() {
        var countryException = {
          Id: 0,
          Country: null,
          StartDate: moment.utc(),
          IsActive: true,
        };

        (<Array<any>>this.gvwCountryException.data).splice(
          0,
          0,
          countryException
        );
      }

      SaveTariffBookNote(): ng.IPromise<boolean> {
        var deferre = this.$q.defer<boolean>();

        this.tariffBookNote.NotesEnglishUK = this.NotesEnglishUKHTML;
        this.tariffBookNote.NotesFrench = this.NotesFrenchHTML;
        this.tariffBookNote.NotesSpanish = this.NotesSpanishHTML;
        this.tariffBookNote.NotesPortuguese = this.NotesPortugueseHTML;

        var _CountryExceptionList =
          this.tariffBookNote.TariffBookNoteExceptionList;

        if (this.gridCountryExceptionApi) {
          this.tariffBookNote.TariffBookNoteExceptionList = _.map(
            this.gridCountryExceptionApi.rowEdit.getDirtyRows(
              this.gridCountryExceptionApi.grid
            ),
            (o) => {
              return o.entity;
            }
          );
        } else {
          this.tariffBookNote.TariffBookNoteExceptionList = [];
        }

        this.tariffBookNotesService.saveTariffBookNote().save(
          this.tariffBookNote,
          (data: interfaces.applicationcore.IMessageHandler) => {
            this.generalService.displayMessageHandler(data);

            if (this.gridCountryExceptionApi) {
              this.gridCountryExceptionApi.rowEdit.setRowsClean(
                this.tariffBookNote.TariffBookNoteExceptionList
              );
            }

            if (data.ID)
              this.tnnId = Number(data.ID);

            if (data.HasErrorMessage)
              deferre.resolve(false);
            else 
              deferre.resolve(true);
          },
          (errorResponse) => {
            if (this.tnnId === 0) {
              this.tariffBookNote.TariffBookNoteExceptionList =
                _CountryExceptionList;
            }

            this.generalService.displayMessageHandler(
              <interfaces.applicationcore.IMessageHandler>errorResponse.data
            );
            deferre.resolve(false);
          }
        );

        return deferre.promise;
      }

      Save() {
        this.bsLoadingOverlayService.wrap({
          referenceId: 'tariffbooknote.update'
      },
          () => {
              return this.SaveTariffBookNote().then((data: boolean) => {
                  if (data) {
                      this.loadTariffBookNote();
                      this.$anchorScroll("topAnchor");
                      this.$state.go("auth.MasterData.TariffBookNotes.Update", { tnnId: this.tnnId});
                  }
              });
          });
      }


      SaveAndClose() {
        this.bsLoadingOverlayService.wrap({
          referenceId: 'tariffbooknote.update'
      },
          () => {
              return this.SaveTariffBookNote().then((data) => {
                  if (data) {
                      this.$state.go("^");
                  } else {
                      this.$anchorScroll("topAnchor");
                  }
              });
          });
      }

      Close() {
        this.$state.go("^");
      } 

      changeNotesEnglishUKHTML(){
        if (this.viewNotesEnglishUKHTML){
          this.NotesEnglishUKHTML = this.tariffBookNote.NotesEnglishUK;
        }
        else{
          this.tariffBookNote.NotesEnglishUK = this.NotesEnglishUKHTML;
        }
      }


      changeNotesFrenchHTML(){
        if (this.viewNotesFrenchHTML){
          this.NotesFrenchHTML = this.tariffBookNote.NotesFrench;
        }
        else{
          this.tariffBookNote.NotesFrench = this.NotesFrenchHTML;
        }
      }      

      changeNotesSpanishHTML(){
        if (this.viewNotesSpanishHTML){
          this.NotesSpanishHTML = this.tariffBookNote.NotesSpanish;
        }
        else{
          this.tariffBookNote.NotesSpanish = this.NotesSpanishHTML;
        }
      }        


      changeNotesPortugueseHTML(){
        if (this.viewNotesPortugueseHTML){
          this.NotesPortugueseHTML = this.tariffBookNote.NotesPortuguese;
        }
        else{
          this.tariffBookNote.NotesPortuguese = this.NotesPortugueseHTML;
        }
      }   

 
      PasteHTMLNotesEnglishUK(){
        const trix_element = document.querySelector<any>("#trix-editor-englishuk");
        navigator['clipboard'].readText().then((data) => {
          trix_element.editor.insertHTML(data);
        });
      }

    }

    angular
      .module("app")
      .controller(
        "tariffBookNotesUpdateCtrl",
        controllers.tariff.tariffBookNotesUpdateCtrl
      );
  }
}
